




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'Mission',
  components: {
    DeskMisson: () => import('../desk/misson.vue'),
    MobileMission: () => import('../mobile/misson.vue')
  },
  setup() {
    const missionType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskMisson' : 'MobileMission'
    )
    return {
      missionType
    }
  }
})
