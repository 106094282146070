




import { defineComponent, computed } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'VideoBanner',
  components: {
    DeskBanner: () => import('../desk/banner.vue'),
    MobileBanner: () => import('../mobile/banner.vue')
  },
  emits: ['change-play'],
  setup(props, { emit }) {
    const bannerType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskBanner' : 'MobileBanner'
    )

    const handleChange = () => {
      emit('change-play', true)
    }

    return {
      bannerType,
      handleChange
    }
  }
})
