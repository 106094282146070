




import { defineComponent, computed } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'ProductService',
  components: {
    DeskProduct: () => import('../desk/product.vue'),
    MobileProduct: () => import('../mobile/product.vue')
  },
  setup() {
    const productType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskProduct' : 'MobileProduct'
    )
    return {
      productType
    }
  }
})
