










import { defineComponent, ref } from '@vue/composition-api'
import VideoBanner from './page/vedio-banner.vue'
// import FullVideo from './page/full-video.vue'
import Mission from './page/mission.vue'
import productService from './page/product-service.vue'
import newsService from './page/news-service.vue'

export default defineComponent({
  name: 'Home',
  components: { VideoBanner, Mission, productService, newsService },
  setup() {
    //  全屏视频播放状态
    const play = ref<boolean>(false)

    const handleVedio = (state: boolean) => {
      play.value = state
    }

    return {
      play,
      handleVedio
    }
  }
})
