




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'NewsService',
  components: {
    DeskNews: () => import('../desk/news.vue'),
    MobileNews: () => import('../mobile/news.vue')
  },
  setup() {
    const newsType = computed(() =>
      AppModule.device == DeviceType.Desktop ? 'DeskNews' : 'MobileNews'
    )

    return {
      newsType
    }
  }
})
